import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Card, ComboboxItem, Select, Menu, Button, Text, SimpleGrid, AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import {
  IconBoxSeam,
  IconClipboardList,
  IconCurrencyNaira,
  IconUsersGroup,
  IconUserStar,
  IconWheelchair,
} from "@tabler/icons-react";
import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import RecentTransactions from "../RecentTransactions";
import { AppContext } from "../../Context/AppContext";
import { getFinancialValueFromNumeric } from "../../Lib/Methods";

export default function Overview() {
  const context = useContext(AppContext);
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const [salesPeriod, setSalesPeriod] = useState<"today" | "week" | "all" | string>("today");
  return (
    <AppShell padding="lg">
      <Container maxWidth="xl" className="overview-container">
        <Container maxWidth="md" className="overview-container">
          <SimpleGrid
            cols={{ base: 2, xs: 2, sm: 2, md: 2, lg: 2 }}
            spacing={{ base: 10, sm: "xl" }}
            verticalSpacing={{ base: "md", sm: "xl" }}
          >
            <Card shadow="sm" radius="md" withBorder className="stat-card">
              <Card.Section>
                <SimpleGrid
                  cols={{ base: 1, xs: 1, sm: 1, md: 3, lg: 3 }}
                  spacing={{ base: 1 }}
                  verticalSpacing={{ base: 5 }}
                >
                  <IconClipboardList color="#1971c2" size={45} />
                  <div className="flex-col card-content">
                    <Text fw={400} size="15px" color="#808080">
                      My Balance
                    </Text>
                    <Text fw={500} size="30px" style={{ marginTop: "3px" }} color="#333333">
                      ₦{getFinancialValueFromNumeric(context?.profile?.balance ?? 0)}
                    </Text>
                    <div className="none-visibility">Bing Bong</div>
                  </div>
                </SimpleGrid>
              </Card.Section>
            </Card>
            <Card shadow="sm" radius="md" withBorder className="stat-card">
              <Card.Section>
                <SimpleGrid
                  cols={{ base: 1, xs: 1, sm: 1, md: 3, lg: 3 }}
                  spacing={{ base: 1 }}
                  verticalSpacing={{ base: 5 }}
                >
                  <IconCurrencyNaira color="#1971c2" size={45} />
                  <div className="flex-col card-content">
                    <Text fw={400} size="15px" color="#808080">
                      Payout
                    </Text>
                    <Text fw={500} fz="14px" style={{ marginTop: "3px" }} color="#333333">
                      View payouts
                    </Text>
                    <Link to="/dashboard/payouts">
                      <Button fullWidth className="btn">
                        View All
                      </Button>
                    </Link>
                  </div>
                </SimpleGrid>
              </Card.Section>
            </Card>
          </SimpleGrid>
        </Container>
        <RecentTransactions />
      </Container>
    </AppShell>
  );
}
