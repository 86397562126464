import { createContext, useContext } from "react";
import { Admin, Department, Outlet, Role, Vendor } from "../Lib/Types";

export interface ContextProps {
  logoutVendor: () => void;
  department: Department;

  profile: Vendor | undefined;
  isProfileLoading: boolean;
  reloadProfile: () => void;

  outlets: Outlet[] | undefined;
  isOutletsLoading: boolean;
  reloadOutlets: () => void;
}
const AppContext = createContext<ContextProps | null>(null);

export { AppContext };
