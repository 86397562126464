import { useState, useEffect } from "react";

import {} from "react-router-dom";

import { Button, Card, TextInput, Text, Group, Paper, ThemeIcon, rem, Badge } from "@mantine/core";
import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import "./styles.scss";
import StatsClasses from "./ProductStats.module.css";
import ActionsClasses from "./ProductActions.module.css";
import { IconClipboard, IconEye, IconUser } from "@tabler/icons-react";

export default function SingleProduct() {
  useEffect(() => {
    nprogress.start();
    document.title = "View Product";
  }, []);
  useEffect(() => {
    nprogress.complete();
  });

  const gridStatsProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  };
  const gridActionsProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  };
  return (
    <Container className="product-container" maxWidth="lg">
      <Text fw={500} size="20px">
        Product Details
      </Text>
      <br />
      <Grid container spacing={2}>
        <Grid item {...gridStatsProps}>
          <Paper withBorder p="md" radius="md">
            <Group justify="space-between">
              <Text size="xs" c="dimmed" className={StatsClasses.title}>
                Total Patients
              </Text>
              <IconUser className={StatsClasses.icon} size="1.4rem" stroke={1.5} />
            </Group>
            <Text className={StatsClasses.value} size="20px">
              56
            </Text>
            <Text fz="xs" c="dimmed" mt={7}>
              Patients who have purchased this service
            </Text>
          </Paper>
        </Grid>
        <Grid item {...gridStatsProps}>
          <Paper withBorder p="md" radius="md">
            <Group justify="space-between">
              <Text size="xs" c="dimmed" className={StatsClasses.title}>
                Total Orders
              </Text>
              <IconClipboard className={StatsClasses.icon} size="1.4rem" stroke={1.5} />
            </Group>
            <Text className={StatsClasses.value} size="20px">
              122
            </Text>
            <Text fz="xs" c="dimmed" mt={7}>
              Total number of orders for this product
            </Text>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item {...gridActionsProps}>
          <Paper radius="md" withBorder className={ActionsClasses.card} mt={20}>
            <Text ta="center" fw={700} className={ActionsClasses.title}>
              Publishing
            </Text>
            <br />
            <Badge color="green">Product is Published!</Badge>
            <Text c="dimmed" ta="center" fz="sm" className={ActionsClasses.body}>
              When a product is published, it is visible to the Patients to purchase. If a product/service is
              Unpublished, it cannot be interacted with by a Patient.
            </Text>

            <Button variant="subtle" color="red">
              Unpublish Product
            </Button>
          </Paper>
        </Grid>
        <Grid item {...gridActionsProps}>
          <Paper radius="md" withBorder className={ActionsClasses.card} mt={20}>
            <Text ta="center" fw={700} className={ActionsClasses.title}>
              Activation
            </Text>
            <br />
            <Badge color="red">Product is Deactivated!</Badge>
            <Text c="dimmed" ta="center" fz="sm" className={ActionsClasses.body}>
              When a product is deactivated, a Vendor is unable to market the product and Patients are unable
              to purchase it.
            </Text>

            <Button variant="subtle" color="green">
              Activate Product
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Card>
            <Card.Section></Card.Section>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      </Grid>
    </Container>
  );
}
