import { useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Card, Badge, CopyButton, Group, Popover, Text, Button, Paper, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";

import {
  generateRandomString,
  getBadgeColorFromStatus,
  getFinancialValueFromNumeric,
  getFullDate,
  getHalfDate,
} from "../../Lib/Methods";
import { Statuses } from "../../Lib/Types";
import classes from "./CardStyles.module.css";
import { IconInfoCircle } from "@tabler/icons-react";

export default function SinglePatient() {
  const [isPopoverOpen, { close: ClosePopover, open: OpenPopover }] = useDisclosure(false);

  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const transactionID = generateRandomString(12);
  return (
    <Container maxWidth="lg" className="single-transaction-container">
      <div className="flex-row justify-between width-100">
        <Text fw={500} size="20px" mb={5}>
          Transaction Details
        </Text>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print
        </Button>
      </div>

      <br />
      <div>
        <Container maxWidth="md" className="transaction-details">
          <div className="flex-col width-100 justify-center align-center">
            <Text c="dimmed">Amount</Text>

            <div className="flex-row align-end">
              {/* <Text fw={500} c="dimmed" size="20px" ml={-35} my={10} className="strike">
                ₦{getFinancialValueFromNumeric(1000)}
              </Text> */}
              &nbsp;
              <Text fw={500} size="30px" my={10}>
                ₦{getFinancialValueFromNumeric(900)}
              </Text>
            </div>

            <Badge color={getBadgeColorFromStatus("Success")} className="uppercase" size="lg">
              Success
            </Badge>
          </div>
          <br />
          <Paper withBorder p={15} mt={20}>
            <div className="flex-row width-100 justify-center">
              <Text fw={500} size="17px">
                Payment Information
              </Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Amount Paid
              </Text>
              <Text size="17px">₦{getFinancialValueFromNumeric(1000)}</Text>
            </div>
            {/* <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Quickhealth Fee (10%)
              </Text>
              <Text size="17px" c="red">
                -₦{getFinancialValueFromNumeric(100)}
              </Text>
            </div> */}
            {/* DO NOT NOTIFY VENDORS ABOUT QUICKHEALTH FEE */}
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Amount Payable
              </Text>
              <Text size="20px" c="green">
                ₦{getFinancialValueFromNumeric(900)}
              </Text>
            </div>
            <Divider my={10} />
            <div className="flex-row width-100 justify-center">
              <Text fw={500} size="17px">
                Patient Information
              </Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Service Token
              </Text>
              <div className="flex-row align-center">
                <Badge variant="outline" color="green" size="lg">
                  <Text fw={600} className="uppercase">
                    dj93jd8f93
                  </Text>
                </Badge>
                &nbsp;
                <Popover width={200} position="bottom" withArrow shadow="md" opened={isPopoverOpen}>
                  <Popover.Target>
                    <IconInfoCircle color="#1971c2" onMouseEnter={OpenPopover} onMouseLeave={ClosePopover} />
                  </Popover.Target>
                  <Popover.Dropdown style={{ pointerEvents: "none" }}>
                    <Text size="md" fw={500}>
                      This unique code must be provided by the Patient each time the patient calls!
                    </Text>
                  </Popover.Dropdown>
                </Popover>
              </div>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Patient Name
              </Text>
              <Text size="17px">Lord Braavosi</Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Email
              </Text>
              <Text size="17px">me@thejstars.com</Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Phone
              </Text>
              <Text size="17px" c="#1971c2">
                08118922299
              </Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Location
              </Text>
              <Text size="17px">2 Yellow Brick Rd, Oz</Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Order Date
              </Text>
              <Text size="17px">{getFullDate(Date.now())}</Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Expiry Date
              </Text>
              <Text size="17px">{getFullDate(Date.now() + 1000 * 60 * 60 * 24 * 30)}</Text>
            </div>
            <div className="flex-row align-center justify-between width-100 item">
              <Text fw={500} c="dimmed">
                Transaction Reference
              </Text>
              <CopyButton value={transactionID}>
                {({ copied, copy }) => {
                  return (
                    <Text size="17px" className="uppercase pointer" c={copied ? "green" : "blue"} fw={500} onClick={copy}>
                      {transactionID}
                    </Text>
                  );
                }}
              </CopyButton>
            </div>
          </Paper>
        </Container>
        <br />
        <br />
      </div>
    </Container>
  );
}
