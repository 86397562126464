const baseURL = "https://quickhealth-production-server.up.railway.app";

const Endpoints = {
  Login: `${baseURL}/auth/vendor/login`,
  ValidateOTP: `${baseURL}/auth/vendor/otp?token`,
  UpdateVendorProfile: `${baseURL}/admin/vendor/update?id`,
  UpdateVendorBankDetails: `${baseURL}/admin/bank/add`,

  CreateNewOutlet: `${baseURL}/outlet/onboard`,
  GetOutlets: `${baseURL}/outlet/vendor/all`,
  DeleteOutlet: `${baseURL}/outlet/delete`,
  UpdateOutlet: `${baseURL}/outlet/update`,

  GetProfile: `${baseURL}/vendor/profile`,
  UpdateProfile: `${baseURL}/vendor/profile`,

  CreateProduct: `${baseURL}/product/create`,
  UpdateProduct: `${baseURL}/product/edit`,
  GetVendorProducts: `${baseURL}/product/all`,
  DeleteProduct: `${baseURL}/product/delete`,
  PublishProduct: `${baseURL}/product/publish`,
  GetSingleProduct: `${baseURL}/product/find`,
  FilterProducts: `${baseURL}/product/filter`,

  GetAllTransactions: `${baseURL}/transaction/vendor/all`,
  GetSingleTransaction: `${baseURL}/transaction`,

  UploadFile: `${baseURL}/picture/upload`,
};

export { Endpoints, baseURL };
