import { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Card, Badge, CopyButton, Group, Alert, Text, Button, Paper, Divider, Table } from "@mantine/core";

import {
  generateRandomString,
  getFinancialValueFromNumeric,
  getFullDate,
  getHalfDate,
} from "../../Lib/Methods";

import "./styles.scss";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { Service, Transaction } from "../../Lib/Types";
import { PaginatedResponse } from "../../Lib/Responses";
import { Endpoints } from "../../Lib/Endpoints";
import Loader from "../../Misc/Loader";
export default function SingleTransaction() {
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const { transactionID } = useParams();
  const {
    data: transaction,
    response: transactionResponse,
    isLoading: isLoadingTransaction,
    reloadData: reloadTransaction,
  } = usePerformRequest<Transaction, PaginatedResponse<Transaction>>({
    method: "GET",

    url: `${Endpoints.GetSingleTransaction}/${transactionID}`,
  });

  return (
    <>
      {isLoadingTransaction ? (
        <Container maxWidth="lg" className="single-transaction-container">
          <Loader />
        </Container>
      ) : (
        <Container maxWidth="lg" className="single-transaction-container">
          {transaction ? (
            <>
              <div className="flex-row justify-between width-100">
                <Text fw={500} size="20px" mb={5}>
                  Transaction Details
                </Text>
                <Button
                  onClick={() => {
                    window.print();
                  }}
                >
                  Print
                </Button>
              </div>

              <br />
              <div>
                <Container maxWidth="md" className="transaction-details">
                  <div className="flex-col width-100 justify-center align-center">
                    <Text c="dimmed">Amount</Text>

                    <Text fw={500} size="30px" my={10}>
                      ₦{getFinancialValueFromNumeric(transaction.amount)}
                    </Text>

                    <Badge color="green" className="uppercase" size="lg">
                      Success
                    </Badge>
                  </div>
                  <br />
                  <Paper withBorder p={15} mt={20}>
                    <div className="flex-row align-center justify-between width-100 item">
                      <Text fw={500} c="dimmed">
                        Patient Name
                      </Text>
                      <Text size="17px" className="capitalize">
                        {transaction?.patient?.firstname} {transaction?.patient?.lastname}
                      </Text>
                    </div>
                    <div className="flex-row align-center justify-between width-100 item">
                      <Text fw={500} c="dimmed">
                        Date
                      </Text>
                      <Text size="17px">{getFullDate(parseInt(transaction.timestamp))}</Text>
                    </div>
                    <div className="flex-row align-center justify-between width-100 item">
                      <Text fw={500} c="dimmed">
                        Quickhealth Token
                      </Text>

                      <Text className="value uppercase">{transaction?.token}</Text>
                    </div>
                    <div className="flex-row align-center justify-between width-100 item">
                      <Text fw={500} c="dimmed">
                        Transaction Reference
                      </Text>
                      <CopyButton value={transactionID ?? ""}>
                        {({ copied, copy }) => {
                          return (
                            <Text
                              size="17px"
                              className="uppercase pointer"
                              c={copied ? "green" : "blue"}
                              fw={500}
                              onClick={copy}
                            >
                              {transaction.reference}
                            </Text>
                          );
                        }}
                      </CopyButton>
                    </div>
                    <Divider mb="20px" />
                    <div className="flex-row width-100 justify-center">
                      <Text fw={500} size="17px">
                        Order Details
                      </Text>
                    </div>

                    {transaction?.offerings?.map((offering) => {
                      return (
                        <Paper withBorder my={10}>
                          <Table className="table" verticalSpacing="md" withRowBorders={false}>
                            <Table.Tbody>
                              <Table.Tr>
                                <Table.Td>
                                  <Text className="label">Product Name</Text>
                                </Table.Td>
                                <Table.Td>
                                  <Text className="capitalize">{offering.name}</Text>
                                </Table.Td>
                              </Table.Tr>
                              <Table.Tr>
                                <Table.Td>
                                  <Text className="label">Vendor</Text>
                                </Table.Td>
                                <Table.Td>
                                  <Text className="capitalize">{offering.provider.name}</Text>
                                </Table.Td>
                              </Table.Tr>
                            </Table.Tbody>
                          </Table>
                        </Paper>
                      );
                    })}
                  </Paper>
                </Container>
                <br />
                <br />
              </div>
            </>
          ) : (
            <Container maxWidth="sm">
              <Alert title="Empty Cart">
                <Text fz="md" fw={500}>
                  Not found!
                </Text>
                <div className="flex-row align-center">
                  <Text c="dimmed">The selected transaction does not exist!</Text>
                  &nbsp; &nbsp;
                </div>
              </Alert>
            </Container>
          )}
        </Container>
      )}
    </>
  );
}
