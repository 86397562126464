import { useState, useContext, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { TextInput, Select, Button, Popover, Text, SimpleGrid, Card, NumberInput } from "@mantine/core";
import { IconPencil, IconCheck, IconClock, IconPhone } from "@tabler/icons-react";
import { useForm } from "@mantine/form";

import { AppContext } from "../../Context/AppContext";
import { PaginationProps, Service, Vendor } from "../../Lib/Types";
import { DefaultErrorNotification, DefaultSuccessNotification, validatePhone } from "../../Lib/Methods";
import { DefaultResponse, PaginatedResponse } from "../../Lib/Responses";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { Banks } from "../../Lib/Banks";
import { VerifyAccountNumber } from "../../Lib/Paystack.methods";

import "./styles.scss";

export default function ProfileTab() {
  const context = useContext(AppContext);

  const vendor = context?.profile;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEditProfile, setEditProfile] = useState<boolean>(false);
  const [isEditBankDetails, setEditBankDetails] = useState<boolean>(false);

  const accountForm = useForm({
    initialValues: {
      vendorname: "",
      firstname: "",
      phone: "",
      rate: 0,
      lastname: "",
      email: "",
      department: "",
    },
    validate: {
      vendorname: (value) => (value.toString().length === 0 ? "Provide company name" : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Please enter a valid email"),
      phone: (value) => (!validatePhone(value) ? "Enter a valid phone number" : null),
      firstname: (value) => (value.toString().length === 0 ? "Provide vendor admin Firstname" : null),
      lastname: (value) => (value.toString().length === 0 ? "Enter vendor admin Lastname" : null),
      department: (value) => (value.toString().length === 0 ? "Select company department" : null),
    },
  });

  const vendorBankAccountForm = useForm({
    initialValues: {
      bank_code: "",
      account_number: "",
      account_name: "",
    },
    validate: {
      bank_code: (value) => (value.length < 2 ? "Please select a bank!" : null),
      account_number: (value) => (value.length !== 10 ? "Please enter a valid account number!" : null),
    },
  });

  useEffect(() => {
    if (vendor) {
      accountForm.setValues({
        vendorname: vendor.vendorname,
        firstname: vendor.firstname,
        lastname: vendor.lastname,
        email: vendor.email,
        phone: vendor.phone,
        department: vendor.department,
        rate: vendor.rate,
      });
    }
  }, [vendor]);

  const UpdateVendorProfile = async () => {
    const { hasErrors } = accountForm.validate();
    if (!hasErrors) {
      const { vendorname, firstname, lastname, email, department, phone, rate } = accountForm.values;
      setLoading(true);
      const r: DefaultResponse = await PerformRequest({
        method: "PUT",
        route: `${Endpoints.UpdateVendorProfile}=${vendor?.id}`,
        data: {
          firstname,
          email,
          lastname,
          vendorname,
          phone: phone.toString(),
          rate,
          department,
        },
      }).catch(() => {
        setLoading(false);
      });
      setLoading(false);
      setEditProfile(false);
      if (r && r.data && r.data.statusCode === 200) {
        context?.reloadProfile();
        DefaultSuccessNotification("Profile Updated!");
      }
    }
  };
  const [isBankAccountLoading, setBankAccountLoading] = useState<boolean>(false);
  const [isUpdateBankDisabled, setUpdateBankDisabled] = useState<boolean>(true);
  const UpdateVendorBankAccount = async () => {
    const { hasErrors } = vendorBankAccountForm.validate();
    if (!hasErrors) {
      const { account_number, bank_code, account_name } = vendorBankAccountForm.values;
      setBankAccountLoading(true);
      const bankName = Banks.find((b) => b.code === bank_code)?.name;
      const r: DefaultResponse = await PerformRequest({
        method: "PUT",
        data: {
          name: account_name,
          account: account_number,
          bankCode: bank_code,
          bankName,
        },
        route: `${Endpoints.UpdateVendorBankDetails}/${vendor?.id}`,
      }).catch(() => {
        setBankAccountLoading(false);
        DefaultErrorNotification("An error occurred!");
      });
      setBankAccountLoading(false);
    }
  };
  const VerifyAccountNumberWithPaystack = async () => {
    const { account_number, bank_code } = vendorBankAccountForm.values;
    if (account_number.length === 10 && bank_code.length !== 0) {
      const rValidateBank: any = await VerifyAccountNumber({
        account_number,
        bank_code,
      });
      if (rValidateBank && rValidateBank.data && rValidateBank.data.status) {
        vendorBankAccountForm.setValues({
          account_name: rValidateBank.data.data.account_name,
        });
        setUpdateBankDisabled(false);
      } else {
        setBankAccountLoading(false);
        setUpdateBankDisabled(true);
        vendorBankAccountForm.setValues({
          account_name: "",
        });
        DefaultErrorNotification("Could not add bank account!");
      }
    }
  };
  useEffect(() => {
    VerifyAccountNumberWithPaystack();
  }, [vendorBankAccountForm.values.account_number, vendorBankAccountForm.values.bank_code]);
  return (
    <Container maxWidth="xl" className="account-container">
      {context && vendor && (
        <>
          <br />
          <Text fw={500} size="20px" mb={5}>
            My Profile
          </Text>
          <div className="flex-row align-center">
            <IconClock color="grey" />
            <Text c="dimmed">&nbsp; Date Joined</Text>
            :&nbsp;March 16 2023
          </div>
          <br />
          <SimpleGrid
            cols={{ base: 1, xs: 1, sm: 1, md: 2, lg: 2 }}
            spacing={{ base: 20, xs: 20, sm: 20, md: 20, lg: 20 }}
            verticalSpacing={{ base: 5 }}
          >
            <Card shadow="sm" radius="md" withBorder className="vendor-profile">
              <Card.Section>
                <div className={`section flex-col ${isEditProfile ? "section-active" : ""}`}>
                  {!isEditProfile ? (
                    <>
                      <div className="flex-row">
                        <Text fw={500}>Company Name</Text>:&nbsp;
                        <Text fw={400}>{vendor.vendorname}</Text>
                      </div>
                      <div className="flex-row">
                        <Text fw={500}>First Name</Text>:&nbsp;
                        <Text fw={400}>{vendor.firstname} </Text>
                      </div>
                      <div className="flex-row">
                        <Text fw={500}>Last Name</Text>:&nbsp;
                        <Text fw={400}>{vendor.lastname}</Text>
                      </div>
                      <div className="flex-row">
                        <Text fw={500}>Email</Text>:&nbsp;
                        <Text fw={400}>{vendor.email}</Text>
                      </div>
                      <Button
                        rightSection={<IconPencil size={17} />}
                        onClick={() => {
                          setEditProfile(true);
                        }}
                      >
                        Edit Profile
                      </Button>
                    </>
                  ) : (
                    <>
                      <TextInput
                        label="Company Name"
                        placeholder="Enter Company Name e.g Doe Medical Services"
                        {...accountForm.getInputProps("vendorname")}
                      />

                      <div className="flex-row width-100 justify-between">
                        <TextInput
                          label="First Name"
                          placeholder="Vendor first name e.g Mark"
                          w="47%"
                          {...accountForm.getInputProps("firstname")}
                        />

                        <TextInput
                          label="Last Name"
                          placeholder="Vendor last name e.g Graham"
                          w="47%"
                          {...accountForm.getInputProps("lastname")}
                        />
                      </div>

                      <div className="flex-row width-100 justify-between">
                        <Select
                          searchable
                          label="Department"
                          disabled
                          placeholder="Select vendor department from list"
                          data={[
                            { value: "pharmacy", label: "Pharmacy" },
                            { value: "phlembotomy", label: "Phlembotomy" },
                            { value: "telemedicine", label: "Telemedicine" },
                            { value: "diagnostics", label: "Diagnostics" },
                            {
                              value: "physiotherapy",
                              label: "Physiotherapy",
                            },
                            { value: "dentistry", label: "Dentistry" },
                            {
                              value: "ambulance",
                              label: "Ambulance Services",
                            },
                            {
                              value: "medical supplies",
                              label: "Medical Supplies",
                            },
                          ]}
                          w="47%"
                          {...accountForm.getInputProps("department")}
                        />
                        <NumberInput
                          hideControls
                          label="Rate"
                          min={0}
                          disabled
                          w="47%"
                          maxLength={15}
                          placeholder="Enter company rate"
                          clampBehavior="strict"
                          {...accountForm.getInputProps("rate")}
                        />
                      </div>
                      <div className="flex-row width-100 justify-between">
                        <TextInput
                          label="Email"
                          placeholder="Vendor/Company email"
                          w="47%"
                          {...accountForm.getInputProps("email")}
                        />
                        <NumberInput
                          hideControls
                          prefix="+234 "
                          label="Company Phone"
                          min={0}
                          w="47%"
                          maxLength={15}
                          placeholder="Enter company phone"
                          clampBehavior="strict"
                          leftSection={<IconPhone size={16} />}
                          {...accountForm.getInputProps("phone")}
                        />
                      </div>
                      <Button
                        rightSection={<IconCheck size={17} />}
                        gradient={{ from: "teal", to: "teal", deg: 90 }}
                        onClick={UpdateVendorProfile}
                        loading={isLoading}
                      >
                        Save Profile
                      </Button>
                    </>
                  )}
                </div>
              </Card.Section>
            </Card>
            <Card shadow="sm" radius="md" withBorder className="vendor-profile">
              <Card.Section>
                <div className={`section flex-col ${isEditBankDetails ? "section-active" : ""}`}>
                  {!isEditBankDetails ? (
                    <>
                      <div className="flex-row">
                        <Text fw={500}>Bank Name</Text>:&nbsp;
                        <Text fw={400}>{vendor?.bankDetails?.bankName ?? ""}</Text>
                      </div>
                      <div className="flex-row">
                        <Text fw={500}>Account Number</Text>:&nbsp;
                        <Text fw={400}>{vendor?.bankDetails?.accountNumber ?? ""}</Text>
                      </div>

                      <div className="flex-row">
                        <Text fw={500}>Account Name</Text>:&nbsp;
                        <Text fw={400}>{vendor?.bankDetails?.name ?? ""}</Text>
                      </div>
                      {/* <Button
                        rightSection={<IconPencil size={17} />}
                        onClick={() => {
                          setEditBankDetails(true);
                        }}
                      >
                        Edit Bank Details
                      </Button> */}
                    </>
                  ) : (
                    <>
                      <Select
                        searchable
                        data={Banks.map((bank) => {
                          return { value: bank.code, label: bank.name };
                        })}
                        label="Bank Name"
                        {...vendorBankAccountForm.getInputProps("bank_code")}
                      />
                      <TextInput
                        label="Account Number"
                        placeholder="10-Digit account number"
                        {...vendorBankAccountForm.getInputProps("account_number")}
                        maxLength={10}
                      />

                      <TextInput
                        label="Account Name"
                        placeholder="Full name on account"
                        {...vendorBankAccountForm.getInputProps("account_name")}
                        disabled
                      />
                      <div className="flex-row width-100 justify-between">
                        <Button
                          w="48%"
                          rightSection={<IconCheck size={17} />}
                          gradient={{ from: "teal", to: "teal", deg: 90 }}
                          onClick={() => {
                            UpdateVendorBankAccount();
                          }}
                          loading={isBankAccountLoading}
                          disabled={isUpdateBankDisabled}
                        >
                          Save Details
                        </Button>
                        <Button
                          w="48%"
                          variant="subtle"
                          color="red"
                          onClick={() => {
                            setEditBankDetails(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Card.Section>
            </Card>
          </SimpleGrid>
        </>
      )}
    </Container>
  );
}
