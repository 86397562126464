import { useEffect, useState } from "react";

import { useNavigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { nprogress } from "@mantine/nprogress";

import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import Overview from "../Overview";

import Transactions from "../Transactions";

import SingleTransaction from "../SingleTransaction";

import Patients from "../Patients";

import Account from "../Account";
import { Footer } from "../Footer";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Admin, Outlet, Vendor } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { AppContext, ContextProps } from "../../Context/AppContext";
import Cookies from "js-cookie";
import { DefaultResponse, ProfileResponse } from "../../Lib/Responses";
import { notifications } from "@mantine/notifications";

import "./styles.scss";
import SinglePatient from "../SinglePatient";
import Outlets from "../Outlets";

import Products from "../Products";
import SingleProduct from "../SingleProduct";
import Payouts from "../Payouts";
import Loader from "../../Misc/Loader";
import { Container } from "@mui/material";
export default function Dashboard() {
  const navigate = useNavigate();
  const {
    data: profile,
    isLoading: isProfileLoading,
    response: profileResponse,
    reloadData: reloadProfile,
  } = usePerformRequest<Vendor, ProfileResponse>({
    method: "GET",
    url: Endpoints.GetProfile,
  });

  console.log(profileResponse);
  if (!isProfileLoading && !profileResponse) {
    navigate("/login");
    // Cookies.remove("token");
  }
  const {
    data: outlets,
    isLoading: isOutletsLoading,
    reloadData: reloadOutlets,
  } = usePerformRequest<Outlet[]>({
    method: "GET",
    url: Endpoints.GetOutlets,
  });

  useEffect(() => {
    //Verify if Admin token makes sense 🤔
    // VerifyAdminToken();
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  const logoutVendor = () => {
    Cookies.remove("token");
    navigate("/login");
  };
  return (
    <>
      <AppContext.Provider
        value={{
          department: profile?.department ?? "Pharmacy",
          profile,
          isProfileLoading,
          reloadProfile,

          outlets,
          isOutletsLoading,
          reloadOutlets,

          logoutVendor,
        }}
      >
        {isProfileLoading ? (
          <Container maxWidth="lg">
            <br />
            <br />
            <Loader />
          </Container>
        ) : (
          <>
            <DashboardNav />

            <Routes>
              <Route index path="/" element={<Overview />} />
              <Route index path="/account" element={<Account />} />
              <Route index path="/transactions" element={<Transactions />} />
              <Route index path="/transaction/:transactionID" element={<SingleTransaction />} />
              <Route index path="/outlets" element={<Outlets />} />
              <Route index path="/products" element={<Products />} />
              <Route index path="/product/:productID" element={<SingleProduct />} />
              <Route index path="/patients" element={<Patients />} />
              <Route index path="/patient/:patientID" element={<SinglePatient />} />
              <Route index path="/payouts" element={<Payouts />} />
            </Routes>
            <Footer />
          </>
        )}
      </AppContext.Provider>
    </>
  );
}
