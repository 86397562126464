import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import Cookies from "js-cookie";
import { Card, PinInput, Text, TextInput, Button, TextInputProps } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconAt } from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultResponse,
  LoginResponse,
  ResponseWithHeaders,
  ValidateOTPResponse,
} from "../../Lib/Responses";
import { DefaultErrorNotification, DefaultSuccessNotification } from "../../Lib/Methods";
import { Axios, AxiosError } from "axios";

export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    nprogress.start();
    document.title = "Vendor Login - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });
  const loginForm = useForm({
    initialValues: {
      email: "",
      otp: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Please enter a valid email"),
    },
  });

  const [currentScreen, setCurrentScreen] = useState<"email" | "otp">("email");
  const [token, setToken] = useState<string>("");

  const [isLoading, setLoading] = useState<boolean>(false);
  const [OTP, setOTP] = useState<string>("");

  const RequestOTP = async () => {
    setLoading(true);
    const { email } = loginForm.values;
    const r: DefaultResponse = await PerformRequest({
      method: "POST",
      route: Endpoints.Login,
      data: { email },
    }).catch((e: AxiosError) => {
      console.log(e);
      setLoading(false);
      if (e.status === 404) {
        DefaultErrorNotification("User not found!");
      }
    });
    setLoading(false);
    if (r && r.data && r.data.statusCode === 200) {
      DefaultSuccessNotification("OTP sent to your email");
      setCurrentScreen("otp");
      setToken(r.data.data);
    } else {
      DefaultErrorNotification("An error occurred!");
    }
  };
  const VerifyOTP = async () => {
    setLoading(true);
    const r: ValidateOTPResponse = await PerformRequest({
      method: "POST",
      route: `${Endpoints.ValidateOTP}=${token}`,
      data: { otp: OTP },
    }).catch((e: AxiosError) => {
      console.log(e);
      if (e.response?.status === 500) {
        DefaultErrorNotification("Invalid OTP Provided!");
      }
      setLoading(false);
    });
    console.log(r);
    if (r && r.data && r.data.statusCode === 200) {
      DefaultSuccessNotification("Logged in successfully!");
      Cookies.set("token", r.data.token);
      navigate("/dashboard");
    }
    setLoading(false);
  };

  /* 
  const VerifyOTP = async () => {
    setVerifyingOTP(true);
    const r: LoginResponse = await PerformRequest({
      method: "POST",
      data: { otp: OTP },
      route: `${Endpoints.ValidateOTP}?token=${token}`,
    }).catch(() => {
      setVerifyingOTP(false);
      notifications.show({
        message: "An error occurred!",
        color: "red",
      });
    });
    console.log(r);
    if (r && r.data && r.data.statusCode === 200) {
      Cookies.set("token", r.data.token);
      navigate("/dashboard");
    }
    setVerifyingOTP(false);
  };
*/

  const inputProps: TextInputProps = {
    style: { width: "100%" },
  };
  return (
    <Container maxWidth="lg">
      <div className="login-container flex-col align-center justify-center">
        <img src={Logo} alt="" />
        <br />
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          style={{
            width: "350px",
          }}
        >
          <form
            className="login-form flex-col align-center justify-between"
            onSubmit={loginForm.onSubmit(
              (values, _event) => {
                if (currentScreen === "email") {
                  RequestOTP();
                } else {
                  VerifyOTP();
                }
                console.log(values);
              },
              (validationErrors, _values, _event) => {
                console.log(validationErrors, _values, loginForm.errors);
                console.log(Object.keys(validationErrors));
              }
            )}
          >
            <Text fw={500} size="15px">
              Sign In
            </Text>

            <br />

            {currentScreen === "email" ? (
              <>
                <TextInput
                  placeholder="Email Address"
                  leftSection={<IconAt size={16} />}
                  disabled={isLoading}
                  {...loginForm.getInputProps("email")}
                  {...inputProps}
                />
                <br />

                <Button
                  fullWidth
                  loading={isLoading}
                  size="compact-md"
                  style={{
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    if (loginForm.validate().hasErrors) {
                      notifications.show({
                        title: "Error",
                        color: "red",
                        message: "Please fill the form correctly! 🤥",
                      });
                    } else {
                      RequestOTP();
                    }
                  }}
                >
                  Request OTP
                </Button>
              </>
            ) : (
              <>
                <div className="flex-row width-100 align-start justify-start">
                  <Text size="sm" fw={500}>
                    {loginForm.values.email}
                  </Text>
                  &nbsp;
                  <Text size="sm" c="blue">
                    <span
                      className="pointer"
                      onClick={() => {
                        setCurrentScreen("email");
                      }}
                    >
                      Not you?
                    </span>
                  </Text>
                </div>
                <br />
                <PinInput
                  type={/^[0-9]*$/}
                  inputType="tel"
                  length={6}
                  size="md"
                  value={OTP}
                  inputMode="numeric"
                  disabled={isLoading}
                  onChange={(e) => {
                    setOTP(e);
                  }}
                />
                <br />
                <Button
                  fullWidth
                  loading={isLoading}
                  size="compact-md"
                  style={{
                    fontSize: "14px",
                  }}
                  onClick={VerifyOTP}
                >
                  Continue
                </Button>
              </>
            )}
          </form>
          <br />
          {/* <div className="flex-row width-100 justify-between">
            <Text size="sm">Don't have an account?</Text>
            <Text c="blue" size="sm">
              <Link to="/register" className="auth-link">
                Sign Up
              </Link>
            </Text>
          </div> */}
        </Card>
      </div>
    </Container>
  );
}
