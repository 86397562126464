import {
  IconActivity,
  IconBoxSeam,
  IconBuildingStore,
  IconClipboardList,
  IconMoneybag,
  IconUserCircle,
  IconUserCog,
  IconUsersGroup,
  IconWheelchair,
} from "@tabler/icons-react";

const ProtectedRouteList = [
  {
    label: "Patients",
    icon: IconWheelchair,
    route: "patients",
    visible: ["TELEMEDICINE, AMBULANCE, DENTISTRY, PHYSIOTHERAPY"],
  },
  {
    label: "Payouts",
    icon: IconMoneybag,
    route: "payouts",
    visible: ["ALL"],
  },

  {
    label: "Transactions",
    icon: IconClipboardList,
    route: "transactions",
    visible: ["ALL"],
  },
  {
    label: "Products and Services",
    icon: IconBoxSeam,
    route: "products",
    visible: [
      "PHARMACY",
      "PHLEMBOTOMY",
      "DIAGNOSTICS",
      "PHYSIOTHERAPY",
      "DENTISTRY",
      "AMBULANCE",
      "MEDICAL SUPPLIES",
    ],
  },
  {
    label: "Outlets",
    icon: IconBuildingStore,
    route: "outlets",
    visible: [
      "PHARMACY",
      "PHLEMBOTOMY",
      "DIAGNOSTICS",
      "PHYSIOTHERAPY",
      "DENTISTRY",
      "AMBULANCE",
      "MEDICAL SUPPLIES",
    ],
  },
];

export { ProtectedRouteList };
