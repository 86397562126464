import { useState } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { Table, Select, Button, Modal, Text, SimpleGrid, Card } from "@mantine/core";
import { IconPencil, IconCheck, IconClock, IconWallet, IconExclamationCircle } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import "./styles.scss";
import { generateRandomString, getFinancialValueFromNumeric, getFullDate } from "../../Lib/Methods";
const payouts = [
  {
    id: 1,
    name: "Leanne Graham",
    email: "Sincere@april.biz",

    phone: "1-770-736-8031 x56442",
    role: "admin",
  },
  {
    id: 2,
    name: "Ervin Howell",
    email: "Shanna@melissa.tv",

    phone: "010-692-6593 x09125",
    role: "sub-admin",
  },
  {
    id: 3,
    name: "Clementine Bauch",
    email: "Nathan@yesenia.net",

    phone: "1-463-123-4447",
    role: "sub-admin",
  },
  {
    id: 4,
    name: "Patricia Lebsack",
    email: "Julianne.OConner@kory.org",

    phone: "493-170-9623 x156",
    role: "admin",
  },
  {
    id: 5,
    name: "Chelsey Dietrich",
    email: "Lucio_Hettinger@annie.ca",

    phone: "(254)954-1289",
    role: "admin",
  },
  {
    id: 6,
    name: "Mrs. Dennis Schulist",
    email: "Karley_Dach@jasper.info",

    phone: "1-477-935-8478 x6430",
    role: "sub-admin",
  },
  {
    id: 7,
    name: "Kurtis Weissnat",
    email: "Telly.Hoeger@billy.biz",

    phone: "210.067.6132",
    role: "admin",
  },
];
export default function Payouts() {
  return (
    <Container maxWidth="lg" className="payout-tab">
      <div className="flex-row width-100 justify-between">
        <Text fw={500} size="20px">
          Payouts
        </Text>
      </div>

      <Table verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>S/N</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Reference</Table.Th>
            <Table.Th>Amount (₦)</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {payouts.map((payout, index) => {
            return (
              <Table.Tr key={payout.id}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>
                  <Text fz="17px">{getFullDate(Date.now())}</Text>
                </Table.Td>
                <Table.Td className="uppercase">
                  <Text lts={2} fw={500}>
                    {generateRandomString(12)}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text fw={500} c="blue" fz="lg">
                    {getFinancialValueFromNumeric(34542)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Container>
  );
}
