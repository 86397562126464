import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Container, Grid } from "@mui/material";
import { nprogress } from "@mantine/nprogress";

import { Card, Badge, Table, TextInput, Alert, Text, Button, Select, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import { IconInfoCircle, IconAt, IconClock, IconUserShield, IconExternalLink } from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import { ProtectedRouteList } from "../../Lib/ProtectedRoutes";
import DashboardNav from "../DashboardNav";
import { getFinancialValueFromNumeric, getFullDate, getHalfDate } from "../../Lib/Methods";
import Transactions from "../Transactions";

export default function RecentTransactions() {
  useEffect(() => {
    nprogress.start();
    document.title = "Dashboard - Quickhealth";
  }, []);

  useEffect(() => {
    nprogress.complete();
  });

  return (
    <Container maxWidth="lg" className="recent-transactions-container">
      <div className="flex-row justify-between width-100">
        <Text fw={500} size="20px" mb={5}>
          Recent Transactions
        </Text>
        <Link to="/dashboard/transactions">
          <Button variant="subtle" rightSection={<IconExternalLink />}>
            View All
          </Button>
        </Link>
      </div>
      <Transactions recent={true} />
    </Container>
  );
}
