import { useState, useContext, useEffect } from "react";
import { Container } from "@mui/material";

import {
  Textarea,
  Select,
  Table,
  Popover,
  Text,
  Alert,
  Button,
  Modal,
  Group,
  TextInput,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash, IconAt, IconPhone } from "@tabler/icons-react";

import { AppContext } from "../../Context/AppContext";

import Loader from "../../Misc/Loader";

import { DefaultErrorNotification, DefaultSuccessNotification, validateEmail } from "../../Lib/Methods";
import { Vendor } from "../../Lib/Types";
import { DefaultResponse } from "../../Lib/Responses";
import { Endpoints } from "../../Lib/Endpoints";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { DemoOutlets, statesAndLga } from "../../Lib/DemoData";

import "./styles.scss";

export default function Outlets() {
  const context = useContext(AppContext);
  const vendor = context?.profile ?? undefined;
  console.log(context);
  // const outlets = context?.vendor?.outlets;

  const outletForm = useForm({
    initialValues: {
      id: "",
      outletname: "",
      email: vendor?.email ?? "",
      phone: vendor?.phone ?? "",
      address: "",
      lga: "",
      state: "",
    },
    validate: {
      outletname: (value) => (value.length === 0 ? "Please enter Outlet name" : null),
      email: (value) => (!validateEmail(value) ? "Please enter a valid email" : null),
      phone: (value) => (value.toString().length !== 10 ? "Enter a valid phone number" : null),
      address: (value) => (value.toString().length === 0 ? "Enter address" : null),
    },
  });

  const [lgaOptions, setLgaOptions] = useState<any[]>([]);
  const [isLgaDisabled, setLgaDisabled] = useState<boolean>(false);

  const states = statesAndLga.map((state, index) => {
    return { value: state.alias, label: state.state };
  });
  useEffect(() => {
    const { state } = outletForm.values;
    setLgaOptions([]);
    setLgaDisabled(state.length === 0 ? true : false);
    const filter = statesAndLga.find((s) => s.alias === state);
    if (filter) {
      const map = filter.lgas.map((lga) => {
        return { value: lga, label: lga };
      });
      setLgaOptions(map);
    }
  }, [outletForm.values.state]);
  const [isCreateOutletModalOpened, { open: OpenOutletModal, close: CloseOutletModal }] = useDisclosure();

  const [formCase, setFormCase] = useState<"create" | "edit">("create");

  const [isOutletLoading, setOutletLoading] = useState<boolean>(false);
  const [isOutletCreating, setOutletCreating] = useState<boolean>(false);
  const [currentOutletDeleting, setOutletDeleting] = useState<string>("");

  const CreateNewOutlet = async () => {
    const { hasErrors } = outletForm.validate();
    if (!hasErrors) {
      setOutletCreating(true);
      const { outletname, email, phone, address, state, lga } = outletForm.values;
      const values = {
        outletname,
        email,
        phone: phone.toString(),
        address: { area: address, state, lga },
        vendorId: vendor?.id,
      };
      const r: DefaultResponse = await PerformRequest({
        method: "POST",
        route: Endpoints.CreateNewOutlet,
        data: values,
      }).catch(() => {
        DefaultErrorNotification("Error: could not create outlet!");
        setOutletCreating(false);
      });
      setOutletCreating(false);
      if (r && r.data.statusCode === 201) {
        DefaultSuccessNotification("Outlet created!");
        context?.reloadOutlets();
        outletForm.reset();
        CloseOutletModal();
      }
    }
  };
  const UpdateOutlet = async () => {
    const { hasErrors } = outletForm.validate();
    console.log(hasErrors);
    if (!hasErrors) {
      setOutletLoading(true);
      const values = {
        outletname: outletForm.values.outletname,
        email: outletForm.values.email,
        phone: `${outletForm.values.phone.toString()}`,
        address: {
          lga: outletForm.values.lga,
          state: outletForm.values.state,
          area: outletForm.values.address,
        },
      };
      const r: DefaultResponse = await PerformRequest({
        method: "PATCH",
        route: `${Endpoints.UpdateOutlet}/${outletForm.values.id}`,
        data: values,
      }).catch(() => {
        DefaultErrorNotification("Error: could not update outlet!");
        setOutletLoading(false);
      });
      if (r && r.data.statusCode === 200) {
        CloseOutletModal();
        context?.reloadOutlets();
        DefaultSuccessNotification("Outlet updated!");
      }
      setOutletLoading(false);
    }
  };
  const DeleteOutlet = async (outletID: string) => {
    setOutletDeleting(outletID);

    const r: DefaultResponse = await PerformRequest({
      method: "DEL",
      route: `${Endpoints.DeleteOutlet}/${outletID}`,
    }).catch(() => {
      DefaultErrorNotification("Error: could not delete outlet!");
      setOutletDeleting("");
    });
    setOutletDeleting("");
    if (r && r.data.statusCode === 201) {
      DefaultSuccessNotification("Outlet deleted!");
      context?.reloadOutlets();
      outletForm.reset();
    }
  };

  return (
    <Container className="outlets-container" maxWidth="xl">
      {context?.isOutletsLoading ? (
        <Loader />
      ) : (
        <>
          {context && context.outlets && (
            <>
              <Button
                onClick={() => {
                  setFormCase("create");
                  outletForm.reset();
                  OpenOutletModal();
                }}
              >
                Create New Outlet
              </Button>
              <Modal
                opened={isCreateOutletModalOpened}
                onClose={CloseOutletModal}
                centered
                size="lg"
                title={formCase === "create" ? "Create New Outlet" : "Edit Outlet"}
              >
                <div className="outlet-form flex-col justify-between">
                  <TextInput
                    placeholder="Enter Outlet Name e.g ABC Ikeja"
                    label="Outlet Name"
                    disabled={isOutletCreating}
                    {...outletForm.getInputProps("outletname")}
                  />
                  <Group justify="space-between" gap="xs" grow>
                    <TextInput
                      label="Email"
                      placeholder="Outlet contact email"
                      leftSection={<IconAt size={16} />}
                      disabled={isOutletCreating}
                      {...outletForm.getInputProps("email")}
                    />
                    <NumberInput
                      disabled={isOutletCreating}
                      label="Phone number"
                      placeholder="Enter company phone"
                      clampBehavior="strict"
                      min={0}
                      leftSection={<IconPhone size={16} />}
                      prefix="+(234) "
                      hideControls
                      {...outletForm.getInputProps("phone")}
                    />
                  </Group>
                  <Group justify="space-between">
                    <Select
                      label="Select State"
                      searchable
                      placeholder="Select your location"
                      className="form-select"
                      data={[{ value: "", label: "All States" }, ...states]}
                      {...outletForm.getInputProps("state")}
                    />
                    <Select
                      searchable
                      label="LGA"
                      placeholder="Select LGA closest"
                      disabled={isLgaDisabled}
                      data={lgaOptions}
                      className="form-select"
                      {...outletForm.getInputProps("lga")}
                    />
                  </Group>
                  <Textarea
                    label="Address"
                    placeholder="Input address"
                    {...outletForm.getInputProps("address")}
                  />

                  <div className="flex-row width-100 align-center justify-center">
                    <Button
                      fullWidth
                      onClick={() => {
                        if (formCase === "edit") {
                          UpdateOutlet();
                        } else {
                          CreateNewOutlet();
                        }
                      }}
                      loading={isOutletCreating || isOutletLoading}
                    >
                      Create Outlet
                    </Button>
                  </div>
                </div>
              </Modal>

              <>
                {context.outlets.length === 0 ? (
                  <Alert color="#1971c2" title="No Outlets found">
                    <Text>Vendor has no outlets.</Text>
                    <Button
                      onClick={() => {
                        setFormCase("create");
                        outletForm.reset();
                        OpenOutletModal();
                      }}
                    >
                      Create New Outlet
                    </Button>
                  </Alert>
                ) : (
                  <Table horizontalSpacing="md" verticalSpacing="xs" layout="fixed" mt={20}>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Address</Table.Th>
                        <Table.Th>Email</Table.Th>
                        <Table.Th>Phone</Table.Th>
                        <Table.Th>Actions</Table.Th>
                      </Table.Tr>
                    </Table.Tbody>
                    <Table.Tbody>
                      {context.outlets.map((outlet) => {
                        return (
                          <Table.Tr key={outlet.id} className="record">
                            <Table.Td>
                              <Text className="capitalize">{outlet.outletname}</Text>
                            </Table.Td>
                            <Table.Td>
                              <Text className="capitalize">
                                {outlet?.address?.lga}, {outlet?.address?.state}
                              </Text>
                            </Table.Td>

                            <Table.Td>{outlet.email}</Table.Td>
                            <Table.Td>{outlet.phone}</Table.Td>

                            <Table.Td>
                              <div className="flex-row align-center">
                                <Button
                                  variant="subtle"
                                  onClick={() => {
                                    setFormCase("edit");
                                    outletForm.reset();
                                    outletForm.setValues({
                                      id: outlet.id,
                                      outletname: outlet.outletname,
                                      email: outlet.email,
                                      phone: outlet.phone,
                                      address: outlet.address.area ?? "",
                                      lga: outlet.address.lga ?? "",
                                      state: outlet.address.state ?? "",
                                    });
                                    OpenOutletModal();
                                  }}
                                >
                                  Edit
                                </Button>
                                &nbsp; &nbsp;
                                <Popover width={330} position="bottom" withArrow shadow="md">
                                  <Popover.Target>
                                    <Button
                                      color="red"
                                      variant="subtle"
                                      disabled={currentOutletDeleting === outlet.id}
                                    >
                                      <IconTrash size="20px" />
                                    </Button>
                                  </Popover.Target>
                                  <Popover.Dropdown>
                                    <Text fw={500} size="12.5px">
                                      Are you sure you want to delete this outlet?
                                    </Text>
                                    <Button
                                      style={{ marginTop: "10px" }}
                                      color="red"
                                      loading={currentOutletDeleting === outlet.id}
                                      onClick={() => DeleteOutlet(outlet.id)}
                                    >
                                      Delete
                                    </Button>
                                  </Popover.Dropdown>
                                </Popover>
                              </div>
                            </Table.Td>
                          </Table.Tr>
                        );
                      })}
                    </Table.Tbody>
                  </Table>
                )}
              </>
            </>
          )}
        </>
      )}
    </Container>
  );
}
